<script setup lang="ts">
import { register } from 'swiper/element/bundle';
import type { CmsBlock } from '@shopware-pwa/types';

register();

const props = defineProps<{ content: CmsBlock }>();

const slider = ref(null);

// const teasers = props.content.slots;
const teasers = [
  {
    id: '1',
    media: {
      url: 'https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/wintersport-min.png',
    },
    name: 'Wintersport',
    url: '/Aktivitaeten/Wintersport/',
  },
  {
    id: '2',
    media: {
      url: 'https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/yoga-min.png',
    },
    name: 'Yoga',
    url: '/Aktivitaeten/Yoga/',
  },
  {
    id: '3',
    media: {
      url: 'https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/fitness-min.png',
    },
    name: 'Fitness & Running',
    url: '/Aktivitaeten/Fitness-Running/',
  },
  {
    id: '4',
    media: {
      url: 'https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/fahrrad-min.png',
    },
    name: 'Radfahren',
    url: '/Aktivitaeten/Radfahren/',
  },
  {
    id: '5',
    media: {
      url: 'https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/lifestyle.png',
    },
    name: 'Lifestyle',
    url: '/Aktivitaeten/Lifestyle/',
  },
  {
    id: '6',
    media: {
      url: 'https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/bergsport-activity.png',
    },
    name: 'Bergsport',
    url: '/Aktivitaeten/Bergsport/',
  },
  {
    id: '7',
    media: {
      url: 'https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/reisen.png',
    },
    name: 'Reisen',
    url: '/Aktivitaeten/Reisen/',
  },
  {
    id: '8',
    media: {
      url: 'https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/homewear.png',
    },
    name: 'Homewear',
    url: '/Aktivitaeten/Homewear/',
  },
];
</script>
<template>
  <div class="lg:px-15 px-5 py-10">
    <div class="flex flex-col gap-2">
      <div class="flex justify-between">
        <h2>{{ props.content.name }}</h2>
        <div class="flex gap-2">
          <button
            class="bg-transparent"
            aria-label="previous"
            @click="() => slider.swiper.slidePrev()"
          >
            <img v-lazy-load 
              data-src="/icons/shared/arrow-down-active.svg"
              class="h-6 w-6 rotate-90"
              alt="previous"
            />
          </button>
          <button
            class="bg-transparent"
            aria-label="next"
            @click="() => slider.swiper.slideNext()"
          >
            <img v-lazy-load 
              data-src="/icons/shared/arrow-down-active.svg"
              class="rotate-270 h-6 w-6"
              alt="next"
            />
          </button>
        </div>
      </div>
    </div>
    <ClientOnly>
      <swiper-container
        ref="slider"
        :loop="false"
        :slides-per-view="1.25"
        :slides-per-group="1"
        :center-insufficient-slides="true"
        space-between="10"
        :centered-slides="false"
        :breakpoints="{
          1024: {
            slidesPerView: 3.25,
          },
        }"
      >
        <swiper-slide v-for="teaser in teasers" :key="teaser.id">
          <RouterLink :to="teaser.url" class="flex flex-col">
            <NuxtImg
              class="max-w-[560px]"
              :src="teaser.media.url"
              :alt="teaser.name"
            />
            <p
              class="bg-dark absolute bottom-0 block w-full max-w-[560px] px-6 py-3 text-white"
            >
              {{ teaser.name }}
            </p>
          </RouterLink>
        </swiper-slide>
      </swiper-container>
    </ClientOnly>
  </div>
</template>
